/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 10:29:38
 * @Last Modified by: Alejandro Leonor
 * @Last Modified time: 2018-04-19 17:08:15
 */

body {
  font-family: Arial, Helvetica, sans-serif;
}

html {
  font-size: 16px;
}

/* Flex helpers */
.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

/* Margin and padding helpers */
.margin-1 {
  margin: 1rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-left-1 {
  margin-left: 1rem;
}

.margin-right-1 {
  margin-right: 1rem;
}

.margin-half-rem {
  margin: .5rem;
}

.margin-top-half-rem {
  margin-top: .5rem;
}

.margin-bottom-half-rem {
  margin-bottom: .5rem;
}

.margin-left-half-rem {
  margin-left: .5rem;
}

.margin-right-half-rem {
  margin-right: .5rem;
}

/* Size helpers */
.full-width {
  width: 100%;
}

.text-wrap {
  display: flex;
  flex-direction: column;
  text-align-last: left;
}

:where(.css-dev-only-do-not-override-xhxh0a).ant-layout .ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #F1F1F1;
  transition: all 0.2s, background 0s;
}

:where(.css-dev-only-do-not-override-xhxh0a).ant-layout .ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #0B5EA2;
  cursor: pointer;
  transition: all 0.2s;
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline,
.ant-menu-dark>.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: #F1F1F1;
}

.ant-menu-dark .ant-menu-item a,
.ant-menu-dark>.ant-menu .ant-menu-item a,
.ant-menu-dark .ant-menu-item a:hover,
.ant-menu-dark>.ant-menu .ant-menu-item a:hover {
  color: #727272;
}

.ant-legacy-form .ant-legacy-form-item-label>label {
  color: rgb(51, 170, 221);
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
  font-size: 15px;
}

label {
  display: inline;
}

:where(.css-dev-only-do-not-override-xhxh0a).ant-form-item .ant-form-item-label >label {
  position: relative;
  display: inline;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgb(51, 170, 221);
  font-size: 15px;
}

